import React from 'react'
import BgHomeMobile from "../../assets/images/MobileBG.png"
import BGImage2 from "../../assets/images/BGImage2.png"
import ButtonElement from '../../components/ButtonElement'
import Forward from "../../assets/images/Forward.png"
import Backward from "../../assets/images/Backward.png"
import Lottie from "lottie-react";
import petPlaylistAnimation from "../../assets/animations/Animation - 1731334310172.json"
import petCowAnimation from "../../assets/animations/Animation - 1731334342148.json"
import MobileClouds from "../../assets/images/MobileClouds.png"
import MobileInnerBox from "../../assets/images/MobileInnerBox.png"
import MobileGrass from "../../assets/images/MobileGrass.png"
import MobileGrass2 from "../../assets/images/MobileGrass2.png"
import petPlaylistAnimation2 from "../../assets/animations/Animation - 1731468816638.json"
import petPlaylistAnimation3 from "../../assets/animations/Animation - 1731999275954.json"
import useClevertap from '../../hooks/useClevertap'
import ReactGA from 'react-ga4';


type currentBehaviorProps = {
    behaviour: string, image: string
}

type currentPetProps = {
    image: string;
    pet: string;
    behaviours: currentBehaviorProps[];
}

type DesktopProps = {
    tryNewPlaylist: () => void,
    typeOfPlaylist: string,
    setLevel: (level: number) => void,
    handleShare: () => void,
    accessToken: string,
    reveal: boolean,
    isCreating: boolean,
    playlistLink: string,
    youtubeLink: string,
    iframeRef: React.RefObject<HTMLDivElement | null>;
    handleCreatePlaylist: () => void,
    gameStart: boolean | string; // or another specific type if you have it
    handleNextStep: () => void;
    generatedPlaylist: string[]; // assuming it’s an array of song names (strings)
    level: number;
    petName: string;
    currentPet: currentPetProps; // adjust to the specific type of `currentPet`
    isGenerating: boolean;
    setPetName: (name: string) => void;
    handlePreviousPet: () => void;
    handleNextPet: () => void;
    currentBehavior: currentBehaviorProps; // adjust based on the specific type of `currentBehavior`
    handlePreviousBehavior: () => void;
    handleNextBehavior: () => void;
    setTypeOfPlaylist: (type: string) => void;
    handleNextLevel: () => void;
    handleSignIn: () => Promise<void>
};

const Mobile = ({ tryNewPlaylist, typeOfPlaylist, setLevel, handleShare, accessToken, handleSignIn, reveal, isCreating, playlistLink, iframeRef, handleCreatePlaylist, gameStart, handleNextStep, generatedPlaylist, level, petName, currentPet, isGenerating, setPetName, handlePreviousPet, handleNextPet, currentBehavior, handlePreviousBehavior, handleNextBehavior, setTypeOfPlaylist, handleNextLevel, youtubeLink }: DesktopProps) => {
    const { handleEventPushClick } = useClevertap();

    return (
        <div className='flex items-center justify-center min-h-full w-full'
            style={{
                backgroundImage: `url(${BgHomeMobile})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",

            }}
        >
            {level === 5 ? (generatedPlaylist?.length ? <div className='flex flex-col items-center'>

                <div className='flex flex-col items-center mt-10 gap-7'>
                    <div className='flex flex-col items-center gap-2'>
                        <div className='flex flex-col items-center justify-center w-[355px] h-[430px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>

                            <div className='w-[343px] mt-2 h-[410px] border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                                <div className='flex flex-col items-center justify-between py-4 -mt-2 w-[337px] h-[401px] border rounded-[10px] bg-[#262638]'>
                                    <p className="pt-2 font-[joystix] text-[#BE94FB] text-[20px] text-outline"
                                        style={
                                            {
                                                '--outline-width': '1.2px',
                                                '--outline-color': '#5A3D80'
                                            } as React.CSSProperties
                                        }
                                    >{petName}'s Vibe Check</p>
                                    <img src={currentPet.image} alt={currentPet.pet} className="my-3 w-[170.44px] h-[160.28px]" />
                                    <p
                                        className="font-[joystix] text-[#FFFFFF] leading-5 text-center text-[16px] text-outline"
                                        style={{
                                            '--outline-width': '1.2px',
                                            '--outline-color': '#5E5877'
                                        } as React.CSSProperties}
                                    >
                                        We get it - your {currentPet.pet?.length >= 9 ? `${currentPet.pet.slice(0, 9)}...` : currentPet.pet} is a whole mood! We’ve got something to match {petName}’s {currentBehavior.behaviour} energy. Hit play and let the beats set the vibe your furry BFF deserves.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p
                            className="font-[joystix] text-[#262638] text-[18px] text-outline leading-7"
                            style={{
                                '--outline-width': '1.2px',
                                '--outline-color': '#B4A2FF'
                            } as React.CSSProperties}
                        >
                            Jam with {petName}              </p>
                        <div className='flex flex-col items-center justify-center gap-3'>
                            {/* <div className='w-[168px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                <div className='w-[160px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                                    <div className='w-[156px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                        <button
                                            className='text-outline text-[24px] text-[#FFFFFF] w-[160px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#FF001C]'

                                            onClick={() => { }}
                                            style={{
                                                '--outline-width': '1.2px',
                                                '--outline-color': '#5A3D80'
                                            } as React.CSSProperties}
                                        >
                                            YOUTUBE
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                            <div className='w-[168px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                <div className='w-[160px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'> {/* Apply negative margin */}
                                    <div className='w-[156px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                        <button
                                            className='text-outline text-[23px] text-[#FFFFFF] w-[160px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#9FE870]'
                                            onClick={handleCreatePlaylist}
                                            style={{
                                                '--outline-width': '1.2px',
                                                '--outline-color': '#5A3D80'
                                            } as React.CSSProperties}

                                        >
                                            SPOTIFY
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[278px] h-[59px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                <div className='w-[270px] h-[56px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                                    <div className='w-[266px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                        <button
                                            className='text-outline text-[20px] text-[#FFFFFF] w-[260px] h-[55px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#d1c5c7]'

                                            onClick={tryNewPlaylist}
                                            style={{
                                                '--outline-width': '1.2px',
                                                '--outline-color': '#5A3D80'
                                            } as React.CSSProperties}
                                        >
                                            Try New Vibe

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center w-[376px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>
                        <div className='w-[344.09px] mt-2  border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                            <div className='flex flex-col -mt-2 gap-5 items-center justify-center w-[336.87px] border rounded-[10px] bg-[#262638]'>
                                {generatedPlaylist.length > 0 && (
                                    <>
                                        <ul className='rounded-lg shadow-md p-4'>
                                            {generatedPlaylist.slice(0, generatedPlaylist.length).map((song: any, index: number) => (
                                                <li
                                                    className="font-[joystix] text-[#FFFFFF] text-[20px] text-outline leading-7"
                                                    key={index}
                                                    style={{
                                                        '--outline-width': '1.2px',
                                                        '--outline-color': '#5A3D80'
                                                    } as React.CSSProperties}
                                                >
                                                    {song}
                                                </li>
                                            ))}

                                        </ul>
                                    </>
                                )}
                                <a
                                    className="pb-4 font-[joystix] text-center text-[#FEC330] text-[20px] text-outline leading-7"
                                    style={{
                                        '--outline-width': '1.2px',
                                        '--outline-color': '#5A3D80'
                                    } as React.CSSProperties}
                                    href='https://muzify.com/quiz/'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    BY MUZIFY
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : isGenerating ? <Lottie className="h-[300px]" animationData={petPlaylistAnimation3} loop={true} />
                : "")
                : level === 6 ? (isCreating ? <Lottie className="h-[300px]" animationData={petPlaylistAnimation2} loop={true} /> :

                    playlistLink && (
                        <div ref={iframeRef as React.RefObject<HTMLDivElement>} className='mt-8 w-full flex gap-7 flex-col items-center justify-center'>
                            <h2 className='text-2xl px-8 font-[joystix] text-center font-bold text-black mb-4'>Your PetPlaylist is now ready!</h2>
                            <div className='relative flex items-center justify-center w-full' style={{ width: '90%' }}>
                                {/* Blurred iframe section */}
                                <div
                                    className={`${reveal ? 'backdrop-blur-md' : ''} relative w-full h-[380px] flex items-center justify-center`}
                                    style={{
                                        filter: reveal ? 'blur(10px)' : 'none', // Optional extra for more control
                                        transition: 'filter 0.3s ease-in-out',
                                    }}
                                >
                                    <iframe
                                        src={`https://open.spotify.com/embed/playlist/${playlistLink.split('/').pop()}`}
                                        width='100%'
                                        height='100%'
                                        frameBorder='0'
                                        allowTransparency={true}
                                        allow='encrypted-media'
                                        title='Spotify Playlist'
                                    ></iframe>
                                </div>
                                {/* YouTube Playlist */}
                                {youtubeLink && (
                                    <div className='relative flex flex-col items-center justify-center' style={{ width: '45%' }}>
                                        <h3 className='text-xl font-[joystix] text-white mb-2'>YouTube</h3>
                                        <div className={`${reveal ? 'backdrop-blur-md' : ''} relative w-full h-[380px] flex items-center justify-center`}
                                            style={{
                                                filter: reveal ? 'blur(10px)' : 'none',
                                                transition: 'filter 0.3s ease-in-out',
                                            }}
                                        >

                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/?listType=playlist&list=${youtubeLink}`}
                                                title="YouTube Playlist"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                                {/* Reveal button overlay */}
                                {reveal && (
                                    <button
                                        onClick={handleSignIn}

                                        className="absolute bg-[#BE94FB] text-white text-[20px] font-[joystix] w-[300px] h-[50px] rounded-[10px] font-bold"
                                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                                    >
                                        Reveal 🐾
                                    </button>
                                )}
                            </div>
                            <div className='flex flex-col items-center pb-5 justify-between gap-5'>
                                {accessToken && <ButtonElement disabled={!petName} name={"SHARE"} onClick={handleShare} />}
                                <div className='w-[278px] h-[59px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                    <div className='w-[270px] h-[56px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                                        <div className='w-[266px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                            <button
                                                className='text-outline text-[20px] text-[#FFFFFF] w-[260px] h-[55px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'

                                                onClick={tryNewPlaylist}
                                                style={{
                                                    '--outline-width': '1.2px',
                                                    '--outline-color': '#5A3D80'
                                                } as React.CSSProperties}
                                            >
                                                Try New Vibe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) :
                    <div className='flex flex-col items-center justify-center w-[325px] h-[390px] border rounded-[10px] border-[#D9DFEB] bg-[#98B5FD]'>

                        <div className='w-[313px] mt-2 h-[370px] border rounded-[10px] border-[#FFFDFD] bg-[#B283FF]'>
                            <div className='flex flex-col items-center justify-between py-2 -mt-2 w-[307px] h-[361px] border rounded-[10px] bg-[#262638]'>
                                {gameStart === "ready" ? <>
                                    <img src={MobileClouds} className='w-[312.02px] h-[96px]' />
                                    <div className='flex flex-col w-full items-center gap-3 justify-around'>
                                        <p className="font-[joystix] text-center px-16 text-[#BE94FB] text-[22px] text-outline"
                                            style={
                                                {
                                                    '--outline-width': '1.2px',
                                                    '--outline-color': '#D2D2FF'
                                                } as React.CSSProperties
                                            }
                                        >welcome to petplaylist</p>
                                        <p className="font-[joystix] text-center px-0  text-[#FFFFFF] text-[11px]"
                                        // style={
                                        //     {
                                        //         '--outline-width': '1.2px',
                                        //         '--outline-color': '#5A3D80'
                                        //     } as React.CSSProperties
                                        // }
                                        >let’s create a personalised playlist for your pet</p>
                                        <div className='w-[168px] h-[52px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                            <div className='w-[160px] h-[50px] justify-center flex rounded-b-[6px] bg-[#816D93]'> {/* Apply negative margin */}
                                                <div className='w-[156px] h-[57px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                                    <button
                                                        className='text-outline text-[19px] text-[#FFFFFF] w-[260px] h-[51px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'
                                                        onClick={handleNextStep}
                                                        style={
                                                            {
                                                                '--outline-width': '1.2px',
                                                                '--outline-color': '#5A3D80'
                                                            } as React.CSSProperties
                                                        }
                                                    >
                                                        let’s go
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='flex flex-row justify-between w-full pt-2'>
                                        <img src={MobileGrass} className='w-[100px] h-[50px]' />
                                        <img src={MobileGrass2} className='w-[100px] h-[50px]' />
                                    </div></> : gameStart === "set" ?
                                    <div className="flex items-center justify-center">
                                        {/* <Lottie className="h-3/5" animationData={petPlaylistAnimation} loop={true} /> */}
                                        <Lottie className="h-3/5" animationData={petCowAnimation} loop={true} />
                                    </div>
                                    : level < 5 && level >= 1 ? <>
                                        <div className='flex flex-col pt-4 items-center justify-center'>
                                            <p className="font-[joystix] text-[#BE94FB] text-[22px] text-outline"
                                                style={
                                                    {
                                                        '--outline-width': '1.2px',
                                                        '--outline-color': '#5A3D80'
                                                    } as React.CSSProperties
                                                }
                                            >{level}/4</p>
                                            <p
                                                className="px-2 text-center font-[joystix] leading-7 text-[24px] text-[#FFFFFF] text-outline"
                                                style={
                                                    {
                                                        '--outline-width': '1.2px',
                                                        '--outline-color': '#5E5877'
                                                    } as React.CSSProperties
                                                }
                                            >
                                                {level === 1 ? "enter your pet's name" : level === 2 ? "SELECT PET" : level === 3 ? "Behaviour" : "Name this playlist "}
                                            </p>
                                        </div>

                                        {level === 1 ?
                                            <div className='w-[265px] h-[52px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                                <div className='w-[259.78px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                                    <input
                                                        className='text-outline font-[joystix] text-center text-[#FFFFFF] text-[18px] w-[265px] h-[55px] bg-[#D4D3E1] rounded-[6px] border-[3.2px] border-[#918AAE] placeholder:text-[#FFFFFF]'
                                                        style={
                                                            {
                                                                '--outline-width': '1.2px',
                                                                '--outline-color': '#5A3D80'
                                                            } as React.CSSProperties
                                                        }
                                                        onChange={(e) => setPetName(e.target.value)}
                                                        placeholder='TYPE HERE'>
                                                    </input>
                                                </div>
                                            </div> : level === 2 ? (
                                                <div className="flex items-center justify-between px-5 w-full">
                                                    <img src={Backward} className="cursor-pointer w-[25px] h-[36px]" onClick={handlePreviousPet} />
                                                    <div className='flex flex-col'>
                                                        <img src={currentPet.image} alt={currentPet.pet} className="w-[165px] h-[158px]" />
                                                        <p className="text-center pt-3 font-[joystix] text-[18px] text-[#FFFFFF] text-outline">
                                                            {currentPet.pet}
                                                        </p>
                                                    </div>
                                                    <img src={Forward} className="w-[25px] h-[36px] cursor-pointer" onClick={handleNextPet} />
                                                </div>
                                            ) : level === 3 ? (
                                                <div className="flex items-center justify-between w-full px-5">
                                                    <img src={Backward} className="w-[25px] h-[36px] cursor-pointer" onClick={handlePreviousBehavior} />
                                                    <div className="flex flex-col items-center justify-center">
                                                        <img src={currentBehavior.image} alt={currentBehavior.behaviour} className={`${currentBehavior.behaviour === "ENERGETIC" ? 'w-[170px] pl-3' : 'w-[150px]'}`} />
                                                        <p className="text-center font-[joystix] pt-3 text-[18px] text-[#FFFFFF] text-outline">
                                                            {currentBehavior.behaviour}
                                                        </p>
                                                    </div>
                                                    <img src={Forward} className="w-[25px] h-[36px] cursor-pointer" onClick={handleNextBehavior} />
                                                </div>
                                            ) : (
                                                <div className='w-[265px] h-[155px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                                    <div className='w-[259.78px] h-[150px]  rounded-[6px] bg-[#918AAE]'>
                                                        <textarea
                                                            onChange={(e) => {
                                                                setTypeOfPlaylist((e.target as HTMLTextAreaElement).value)
                                                                handleEventPushClick("PET_PLAYLIST_PET_TYPE", {
                                                                    typeOfPlaylist: typeOfPlaylist
                                                                })
                                                                ReactGA.event({
                                                                    category: 'PP Pet type',
                                                                    action: 'input',
                                                                    label: "PET_PLAYLIST_PET_TYPE",
                                                                })
                                                            }}
                                                            className='flex text-outline font-[joystix] text-start text-[#FFFFFF] text-[18px] w-[265px] h-[145px] bg-[#D4D3E1] rounded-[6px] border-[3.2px] border-[#918AAE] placeholder:text-[#FFFFFF] focus:border-transparent'
                                                            style={{
                                                                '--outline-width': '1.2px',
                                                                '--outline-color': '#5A3D80',
                                                                padding: '8px',
                                                                resize: 'none'
                                                            } as React.CSSProperties}
                                                            placeholder='TYPE HERE'
                                                        />


                                                    </div>
                                                </div>
                                            )
                                        }
                                        <ButtonElement disabled={level === 4 ? !typeOfPlaylist : !petName} name={level === 4 ? "CREATE" : "NEXT"} onClick={handleNextLevel} />
                                    </> : <></>}
                            </div>
                        </div>
                    </div>}


        </div>
    )
}

export default Mobile