import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import BgHomeMobile from "../assets/images/MobileBG.png"
import BGHome from "../assets/images/BgHome.png"
import BGImage2 from "../assets/images/BGImage2.png"
import useClevertap from '../hooks/useClevertap'
import useGA from '../hooks/useGA'
import ReactGA from 'react-ga4';

const SharePetPlaylist = () => {
    const { handleEventPushClick } = useClevertap();
    const { recordGAEvent } = useGA();

    const { playlistLink, petName } = useParams()
    const iframeRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const decodedPlaylistLink = playlistLink ? decodeURIComponent(playlistLink) : ''; // Return an empty string or handle the case when playlistLink is undefined
    const playlistId = decodedPlaylistLink.split('/').pop();

    return (
        <>

            <div className='md:hidden h-screen'>
                <div className='flex items-center justify-center min-h-full w-full'
                    style={{
                        backgroundImage: `url(${BgHomeMobile})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",

                    }}>
                    <div ref={iframeRef} className='mt-8 w-full flex gap-7 flex-col items-center justify-center'>
                        <h2 className='text-2xl px-8 font-[joystix] text-center font-bold text-black mb-4'>{petName}'s Playlist is ready with Spotify!</h2>
                        <div className='relative flex items-center justify-center w-full' style={{ width: '90%' }}>
                            {/* Blurred iframe section */}
                            <div
                                className={` relative w-full h-[380px] flex items-center justify-center`}
                                style={{
                                    transition: 'filter 0.3s ease-in-out',
                                }}
                            >
                                <iframe
                                    src={`https://open.spotify.com/embed/playlist/${playlistId}`}
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    allowTransparency
                                    allow="encrypted-media"
                                    title="Spotify Playlist"
                                ></iframe>
                            </div>


                        </div>
                        <div className='flex flex-col items-center pb-5 justify-between gap-5'>
                            <div className='w-[278px] h-[59px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                <div className='w-[270px] h-[56px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                                    <div className='w-[266px] h-[53px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                        <button
                                            className='text-outline text-[20px] text-[#FFFFFF] w-[260px] h-[55px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'

                                            onClick={() => {
                                                navigate("/")
                                                handleEventPushClick("PET_PLAYLIST_SHARED_PAGE_TRY_NEW_PLAYLIST", {
                                                });
                                                ReactGA.event({
                                                    category: 'PP share button',
                                                    action: 'Click',
                                                    label: "PET_PLAYLIST_SHARED_PAGE_TRY_NEW_PLAYLIST",
                                                });
                                            }}
                                            style={{
                                                '--outline-width': '1.2px',
                                                '--outline-color': '#5A3D80'
                                            } as React.CSSProperties}
                                        >
                                            Make your own
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden md:block h-screen'>
                <div className='flex flex-col min-h-screen w-full items-center justify-center'
                    style={{
                        backgroundImage: `url(${BGHome})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className={`flex flex-col items-center justify-center w-[1100px] rounded-[10px] h-[650px]`}
                        style={{
                            backgroundImage: `url(${BGImage2})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <div ref={iframeRef} className='mt-6 w-full flex gap-0 flex-col items-center justify-center'>
                            <h2 className='text-2xl font-[joystix] font-bold text-white mb-4'>{petName}'s PetPlaylist is ready with Spotify!</h2>
                            <div className='relative flex items-center justify-center w-full' style={{ width: '60%' }}>
                                {/* Blurred iframe section */}
                                <div
                                    className={`relative w-full h-[380px] flex items-center justify-center`}
                                    style={{
                                        transition: 'filter 0.3s ease-in-out',
                                    }}
                                >
                                    <iframe
                                        src={`https://open.spotify.com/embed/playlist/${playlistLink?.split('/').pop()}`}
                                        width='100%'
                                        height='100%'
                                        frameBorder='0'
                                        allowTransparency={true}
                                        allow='encrypted-media'
                                        title='Spotify Playlist'
                                    ></iframe>
                                </div>



                            </div>
                            <div className='flex flex-row items-center justify-between gap-5 mt-10'>
                                <div className='w-[388px] h-[49px] justify-center flex rounded-[6px] bg-[#4F3A63]'>
                                    <div className='w-[380px] h-[46px] justify-center flex rounded-b-[6px] bg-[#816D93]'>
                                        <div className='w-[376px] h-[43px] justify-center flex items-center rounded-[6px] -mt-1 bg-[#918AAE]'>
                                            <button
                                                className='text-outline text-[26px] text-[#FFFFFF] w-[370px] h-[45px] justify-center font-[joystix] flex items-center rounded-[6px] bg-[#D4D3E1]'

                                                onClick={() => {
                                                    navigate("/")
                                                    handleEventPushClick("PET_PLAYLIST_SHARED_PAGE_TRY_NEW_PLAYLIST", {
                                                    });
                                                    ReactGA.event({
                                                        category: 'PP share button',
                                                        action: 'Click',
                                                        label: "PET_PLAYLIST_SHARED_PAGE_TRY_NEW_PLAYLIST",
                                                    });
                                                }}
                                                style={{
                                                    '--outline-width': '1.2px',
                                                    '--outline-color': '#5A3D80'
                                                } as React.CSSProperties}
                                            >
                                                Make your own
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SharePetPlaylist