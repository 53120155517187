export const ASSET_MONEY_TITLE = "Asset Money";
export const CLEVERTAP_ACCOUNT_ID = 'RZK-9RW-686Z';
export const GA_TRACKING_ID = "G-SGRZPVFCQ1"
export const petTypeConstant = [
  "Dog",
  "Cat",
  "Bird",
  "Fish",
  "Rabbit",
  "Hamster",
  "Guinea Pig",
  "Ferret",
  "Exotic Pets",
  "Other",
];

export const petBehaviourConstant = [
  "Calm",
  "Lazy",
  "Playful",
  "Active",
  "Energetic",
  "Curious",
  "Affectionate",
  "Shy",
  "Vocal",
  "Anxious",
  "Intelligent",
  "Independent",
  "Social",
  "Fearful",
  "Obedient",
  "Protective",
  "Stubborn",
  "Gentle",
  "Mischievous",
  "Alert",
  "Relaxed",
  "Cuddly",
  "Laid-back",
  "High-Energy",
  "Territorial",
];

export const signup = (pet_name: string) => `
We would love to make playlists that are extremely personal to ${pet_name}.
\n Tell us as much as you can about ${pet_name} and what they like.
`

// signup on 3rd playlist
// change the prompt to add more context
// create a applemusic playlist
// create a applemusic account
// signup to get refresh token from muzify
// create a playlist on apple music

// add urls to spotify, apple, youtube and deezer in db